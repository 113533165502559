@import "minima";
body {
  color: #000000;
  background-color: #ffffff;
}
.org-biblio-detail-header {
}
.org-biblio-results-header {
  /* biblio-results-header-face */
  color: #483d8b;
  font-size: 150%;
  font-weight: bold;
}
.org-bold {
  /* bold */
  font-weight: bold;
}
.org-bold-italic {
  /* bold-italic */
  font-weight: bold;
  font-style: italic;
}
.org-bookmark-menu-bookmark {
  /* bookmark-menu-bookmark */
  font-weight: bold;
}
.org-bookmark-menu-heading {
  /* bookmark-menu-heading */
  color: #228b22;
}
.org-border {
}
.org-browse-url-button {
  /* browse-url-button */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-buffer-menu-buffer {
  /* buffer-menu-buffer */
  font-weight: bold;
}
.org-builtin {
  /* font-lock-builtin-face */
  color: #483d8b;
}
.org-button {
  /* button */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-calendar-month-header {
  /* calendar-month-header */
  color: #0000ff;
}
.org-calendar-today {
  /* calendar-today */
  text-decoration: underline;
}
.org-calendar-weekday-header {
  /* calendar-weekday-header */
  color: #008b8b;
}
.org-calendar-weekend-header {
  /* calendar-weekend-header */
  color: #b22222;
}
.org-change-log-acknowledgment {
  /* change-log-acknowledgment */
  color: #b22222;
}
.org-change-log-conditionals {
  /* change-log-conditionals */
  color: #a0522d;
}
.org-change-log-date {
  /* change-log-date */
  color: #8b2252;
}
.org-change-log-email {
  /* change-log-email */
  color: #a0522d;
}
.org-change-log-file {
  /* change-log-file */
  color: #0000ff;
}
.org-change-log-function {
  /* change-log-function */
  color: #a0522d;
}
.org-change-log-list {
  /* change-log-list */
  color: #a020f0;
}
.org-change-log-name {
  /* change-log-name */
  color: #008b8b;
}
.org-comint-highlight-input {
  /* comint-highlight-input */
  font-weight: bold;
}
.org-comint-highlight-prompt {
  /* comint-highlight-prompt */
  color: #0000cd;
}
.org-comment {
  /* font-lock-comment-face */
  color: #b22222;
}
.org-comment-delimiter {
  /* font-lock-comment-delimiter-face */
  color: #b22222;
}
.org-compilation-column-number {
  /* compilation-column-number */
  color: #8b2252;
}
.org-compilation-error {
  /* compilation-error */
  color: #ff0000;
  font-weight: bold;
}
.org-compilation-info {
  /* compilation-info */
  color: #228b22;
  font-weight: bold;
}
.org-compilation-line-number {
  /* compilation-line-number */
  color: #a020f0;
}
.org-compilation-mode-line-exit {
  /* compilation-mode-line-exit */
  color: #228b22;
  font-weight: bold;
}
.org-compilation-mode-line-fail {
  /* compilation-mode-line-fail */
  color: #ff0000;
  font-weight: bold;
}
.org-compilation-mode-line-run {
  /* compilation-mode-line-run */
  color: #ff8c00;
  font-weight: bold;
}
.org-compilation-warning {
  /* compilation-warning */
  color: #ff8c00;
  font-weight: bold;
}
.org-completions-annotations {
  /* completions-annotations */
  font-style: italic;
}
.org-completions-common-part {
  /* completions-common-part */
  color: #0000cd;
}
.org-completions-first-difference {
  /* completions-first-difference */
  font-weight: bold;
}
.org-constant {
  /* font-lock-constant-face */
  color: #008b8b;
}
.org-cursor {
  /* cursor */
  background-color: #000000;
}
.org-custom-button {
  /* custom-button */
  color: #000000;
  background-color: #d3d3d3;
}
.org-custom-button-mouse {
  /* custom-button-mouse */
  color: #000000;
  background-color: #e5e5e5;
}
.org-custom-button-pressed {
  /* custom-button-pressed */
  color: #000000;
  background-color: #d3d3d3;
}
.org-custom-button-pressed-unraised {
  /* custom-button-pressed-unraised */
  color: #8b008b;
  text-decoration: underline;
}
.org-custom-button-unraised {
  /* custom-button-unraised */
  text-decoration: underline;
}
.org-custom-changed {
  /* custom-changed */
  color: #ffffff;
  background-color: #0000ff;
}
.org-custom-comment {
  /* custom-comment */
  background-color: #d9d9d9;
}
.org-custom-comment-tag {
  /* custom-comment-tag */
  color: #00008b;
}
.org-custom-documentation {
}
.org-custom-face-tag {
  /* custom-face-tag */
  color: #0000ff;
  font-weight: bold;
}
.org-custom-group-subtitle {
  /* custom-group-subtitle */
  font-weight: bold;
}
.org-custom-group-tag {
  /* custom-group-tag */
  color: #0000ff;
  font-size: 120%;
  font-weight: bold;
}
.org-custom-group-tag-1 {
  /* custom-group-tag-1 */
  color: #ff0000;
  font-size: 120%;
  font-weight: bold;
}
.org-custom-invalid {
  /* custom-invalid */
  color: #ffff00;
  background-color: #ff0000;
}
.org-custom-link {
  /* custom-link */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-custom-modified {
  /* custom-modified */
  color: #ffffff;
  background-color: #0000ff;
}
.org-custom-rogue {
  /* custom-rogue */
  color: #ffc0cb;
  background-color: #000000;
}
.org-custom-saved {
  /* custom-saved */
  text-decoration: underline;
}
.org-custom-set {
  /* custom-set */
  color: #0000ff;
  background-color: #ffffff;
}
.org-custom-state {
  /* custom-state */
  color: #006400;
}
.org-custom-themed {
  /* custom-themed */
  color: #ffffff;
  background-color: #0000ff;
}
.org-custom-variable-button {
  /* custom-variable-button */
  font-weight: bold;
  text-decoration: underline;
}
.org-custom-variable-obsolete {
  /* custom-variable-obsolete */
  color: #0000ff;
}
.org-custom-variable-tag {
  /* custom-variable-tag */
  color: #0000ff;
  font-weight: bold;
}
.org-custom-visibility {
  /* custom-visibility */
  color: #3a5fcd;
  font-size: 80%;
  text-decoration: underline;
}
.org-diary {
  /* diary */
  color: #ff0000;
}
.org-diff-added {
  /* diff-added */
  background-color: #eeffee;
}
.org-diff-changed {
}
.org-diff-context {
}
.org-diff-file-header {
  /* diff-file-header */
  background-color: #bfbfbf;
  font-weight: bold;
}
.org-diff-function {
  /* diff-function */
  background-color: #d9d9d9;
}
.org-diff-header {
  /* diff-header */
  background-color: #d9d9d9;
}
.org-diff-hunk-header {
  /* diff-hunk-header */
  background-color: #d9d9d9;
}
.org-diff-index {
  /* diff-index */
  background-color: #bfbfbf;
  font-weight: bold;
}
.org-diff-indicator-added {
  /* diff-indicator-added */
  color: #22aa22;
  background-color: #eeffee;
}
.org-diff-indicator-changed {
  /* diff-indicator-changed */
  color: #aaaa22;
}
.org-diff-indicator-removed {
  /* diff-indicator-removed */
  color: #aa2222;
  background-color: #ffeeee;
}
.org-diff-nonexistent {
  /* diff-nonexistent */
  background-color: #bfbfbf;
  font-weight: bold;
}
.org-diff-refine-added {
  /* diff-refine-added */
  background-color: #bbffbb;
}
.org-diff-refine-changed {
  /* diff-refine-changed */
  background-color: #ffff55;
}
.org-diff-refine-removed {
  /* diff-refine-removed */
  background-color: #ffcccc;
}
.org-diff-removed {
  /* diff-removed */
  background-color: #ffeeee;
}
.org-dired-directory {
  /* dired-directory */
  color: #0000ff;
}
.org-dired-flagged {
  /* dired-flagged */
  color: #ff0000;
  font-weight: bold;
}
.org-dired-header {
  /* dired-header */
  color: #228b22;
}
.org-dired-ignored {
  /* dired-ignored */
  color: #7f7f7f;
}
.org-dired-mark {
  /* dired-mark */
  color: #008b8b;
}
.org-dired-marked {
  /* dired-marked */
  color: #ff8c00;
  font-weight: bold;
}
.org-dired-perm-write {
  /* dired-perm-write */
  color: #b22222;
}
.org-dired-set-id {
  /* dired-set-id */
  color: #ff0000;
  font-weight: bold;
}
.org-dired-special {
  /* dired-special */
  color: #a0522d;
}
.org-dired-symlink {
  /* dired-symlink */
  color: #a020f0;
}
.org-dired-warning {
  /* dired-warning */
  color: #ff0000;
  font-weight: bold;
}
.org-doc {
  /* font-lock-doc-face */
  color: #8b2252;
}
.org-edit-indirect-edited-region {
  /* edit-indirect-edited-region */
  background-color: #ffff00;
}
.org-eldoc-highlight-function-argument {
  /* eldoc-highlight-function-argument */
  font-weight: bold;
}
.org-elfeed-log-date {
  /* elfeed-log-date-face */
  color: #228b22;
}
.org-elfeed-log-debug-level {
  /* elfeed-log-debug-level-face */
  color: #ee00ee;
}
.org-elfeed-log-error-level {
  /* elfeed-log-error-level-face */
  color: #ff0000;
}
.org-elfeed-log-info-level {
  /* elfeed-log-info-level-face */
  color: #00bfff;
}
.org-elfeed-log-warn-level {
  /* elfeed-log-warn-level-face */
  color: #daa520;
}
.org-elfeed-search-date {
  /* elfeed-search-date-face */
  color: #aaa;
}
.org-elfeed-search-feed {
  /* elfeed-search-feed-face */
  color: #aa0;
}
.org-elfeed-search-filter {
  /* elfeed-search-filter-face */
  font-weight: bold;
}
.org-elfeed-search-last-update {
}
.org-elfeed-search-tag {
  /* elfeed-search-tag-face */
  color: #070;
}
.org-elfeed-search-title {
  /* elfeed-search-title-face */
  color: #000;
}
.org-elfeed-search-unread-count {
  /* elfeed-search-unread-count-face */
  color: #000;
}
.org-elfeed-search-unread-title {
  /* elfeed-search-unread-title-face */
  font-weight: bold;
}
.org-epa-field-body {
  /* epa-field-body */
  font-style: italic;
}
.org-epa-field-name {
  /* epa-field-name */
  font-weight: bold;
}
.org-epa-mark {
  /* epa-mark */
  color: #ff0000;
  font-weight: bold;
}
.org-epa-string {
  /* epa-string */
  color: #00008b;
}
.org-epa-validity-disabled {
  /* epa-validity-disabled */
  font-style: italic;
}
.org-epa-validity-high {
  /* epa-validity-high */
  font-weight: bold;
}
.org-epa-validity-low {
  /* epa-validity-low */
  font-style: italic;
}
.org-epa-validity-medium {
  /* epa-validity-medium */
  font-style: italic;
}
.org-error {
  /* error */
  color: #ff0000;
  font-weight: bold;
}
.org-escape-glyph {
  /* escape-glyph */
  color: #a52a2a;
}
.org-ess-XopX {
  /* ess-%op%-face */
  color: #008b8b;
}
.org-ess-assignment {
  /* ess-assignment-face */
  color: #008b8b;
}
.org-ess-bp-fringe-browser {
  /* ess-bp-fringe-browser-face */
  color: #0000cd;
}
.org-ess-bp-fringe-inactive {
  /* ess-bp-fringe-inactive-face */
  color: #696969;
}
.org-ess-bp-fringe-logger {
  /* ess-bp-fringe-logger-face */
  color: #8b0000;
}
.org-ess-bp-fringe-recover {
  /* ess-bp-fringe-recover-face */
  color: #8b008b;
}
.org-ess-constant {
  /* ess-constant-face */
  color: #228b22;
}
.org-ess-debug-blink-ref-not-found {
  /* ess-debug-blink-ref-not-found-face */
  background-color: #8b3a3a;
}
.org-ess-debug-blink-same-ref {
  /* ess-debug-blink-same-ref-face */
  background-color: #4682b4;
}
.org-ess-debug-current-debug-line {
  /* ess-debug-current-debug-line-face */
  background-color: #b4eeb4;
}
.org-ess-function-call {
  /* ess-function-call-face */
  color: #0000ff;
}
.org-ess-keyword {
  /* ess-keyword-face */
  color: #a020f0;
}
.org-ess-matrix {
  /* ess-matrix-face */
  color: #008b8b;
}
.org-ess-modifiers {
  /* ess-modifiers-face */
  color: #008b8b;
}
.org-ess-numbers {
  /* ess-numbers-face */
  color: #228b22;
}
.org-ess-operator {
  /* ess-operator-face */
  color: #008b8b;
}
.org-ess-paren {
  /* ess-paren-face */
  color: #008b8b;
}
.org-ess-r-control-flow-keyword {
  /* ess-r-control-flow-keyword-face */
  color: #a020f0;
}
.org-ess-tracebug-last-input-fringe {
  /* ess-tracebug-last-input-fringe-face */
  color: #0000cd;
  text-decoration: overline;
}
.org-ess-watch-current-block {
  /* ess-watch-current-block-face */
  background-color: #b4eeb4;
}
.org-eww-form-checkbox {
  /* eww-form-checkbox */
  color: #000000;
  background-color: #d3d3d3;
}
.org-eww-form-file {
  /* eww-form-file */
  color: #000000;
  background-color: #808080;
}
.org-eww-form-select {
  /* eww-form-select */
  color: #000000;
  background-color: #d3d3d3;
}
.org-eww-form-submit {
  /* eww-form-submit */
  color: #000000;
  background-color: #808080;
}
.org-eww-form-text {
  /* eww-form-text */
  color: #ffffff;
  background-color: #505050;
}
.org-eww-form-textarea {
  /* eww-form-textarea */
  color: #000000;
  background-color: #C0C0C0;
}
.org-eww-invalid-certificate {
  /* eww-invalid-certificate */
  color: #ff0000;
  font-weight: bold;
}
.org-eww-valid-certificate {
  /* eww-valid-certificate */
  color: #228b22;
  font-weight: bold;
}
.org-file-name-shadow {
  /* file-name-shadow */
  color: #7f7f7f;
}
.org-fill-column-indicator {
  /* fill-column-indicator */
  color: #7f7f7f;
}
.org-fixed-pitch {
}
.org-fixed-pitch-serif {
}
.org-fringe {
  /* fringe */
  background-color: #f2f2f2;
}
.org-function-name {
  /* font-lock-function-name-face */
  color: #0000ff;
}
.org-geiser-font-lock-autodoc-current-arg {
  /* geiser-font-lock-autodoc-current-arg */
  color: #a0522d;
}
.org-geiser-font-lock-autodoc-identifier {
  /* geiser-font-lock-autodoc-identifier */
  color: #0000ff;
}
.org-geiser-font-lock-doc-button {
  /* geiser-font-lock-doc-button */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-geiser-font-lock-doc-link {
  /* geiser-font-lock-doc-link */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-geiser-font-lock-doc-title {
  /* geiser-font-lock-doc-title */
  font-weight: bold;
}
.org-geiser-font-lock-error-link {
  /* geiser-font-lock-error-link */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-geiser-font-lock-image-button {
  /* geiser-font-lock-image-button */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-geiser-font-lock-repl-input {
  /* geiser-font-lock-repl-input */
  font-weight: bold;
}
.org-geiser-font-lock-repl-output {
  /* geiser-font-lock-repl-output */
  color: #8b2252;
}
.org-geiser-font-lock-repl-prompt {
  /* geiser-font-lock-repl-prompt */
  color: #0000cd;
}
.org-geiser-font-lock-xref-header {
  /* geiser-font-lock-xref-header */
  font-weight: bold;
}
.org-geiser-font-lock-xref-link {
  /* geiser-font-lock-xref-link */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-git-commit-comment-action {
  /* git-commit-comment-action */
  font-weight: bold;
}
.org-git-commit-comment-branch-local {
  /* git-commit-comment-branch-local */
  color: #a0522d;
}
.org-git-commit-comment-branch-remote {
  /* git-commit-comment-branch-remote */
  color: #a0522d;
}
.org-git-commit-comment-detached {
  /* git-commit-comment-detached */
  color: #a0522d;
}
.org-git-commit-comment-file {
  /* git-commit-comment-file */
  color: #8b2252;
}
.org-git-commit-comment-heading {
  /* git-commit-comment-heading */
  color: #a020f0;
}
.org-git-commit-keyword {
  /* git-commit-keyword */
  color: #8b2252;
}
.org-git-commit-known-pseudo-header {
  /* git-commit-known-pseudo-header */
  color: #a020f0;
}
.org-git-commit-nonempty-second-line {
  /* git-commit-nonempty-second-line */
  color: #ff0000;
  font-weight: bold;
}
.org-git-commit-overlong-summary {
  /* git-commit-overlong-summary */
  color: #ff0000;
  font-weight: bold;
}
.org-git-commit-pseudo-header {
  /* git-commit-pseudo-header */
  color: #8b2252;
}
.org-git-commit-summary {
  /* git-commit-summary */
  color: #228b22;
}
.org-glyphless-char {
  /* glyphless-char */
  font-size: 60%;
}
.org-gnus-button {
  /* gnus-button */
  font-weight: bold;
}
.org-gnus-emphasis-bold {
  /* gnus-emphasis-bold */
  font-weight: bold;
}
.org-gnus-emphasis-bold-italic {
  /* gnus-emphasis-bold-italic */
  font-weight: bold;
  font-style: italic;
}
.org-gnus-emphasis-highlight-words {
  /* gnus-emphasis-highlight-words */
  color: #ffff00;
  background-color: #000000;
}
.org-gnus-emphasis-italic {
  /* gnus-emphasis-italic */
  font-style: italic;
}
.org-gnus-emphasis-strikethru {
  /* gnus-emphasis-strikethru */
  text-decoration: line-through;
}
.org-gnus-emphasis-underline {
  /* gnus-emphasis-underline */
  text-decoration: underline;
}
.org-gnus-emphasis-underline-bold {
  /* gnus-emphasis-underline-bold */
  font-weight: bold;
  text-decoration: underline;
}
.org-gnus-emphasis-underline-bold-italic {
  /* gnus-emphasis-underline-bold-italic */
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
}
.org-gnus-emphasis-underline-italic {
  /* gnus-emphasis-underline-italic */
  font-style: italic;
  text-decoration: underline;
}
.org-gnus-group-mail-1 {
  /* gnus-group-mail-1 */
  color: #cd1076;
  font-weight: bold;
}
.org-gnus-group-mail-1-empty {
  /* gnus-group-mail-1-empty */
  color: #cd1076;
}
.org-gnus-group-mail-2 {
  /* gnus-group-mail-2 */
  color: #cd6090;
  font-weight: bold;
}
.org-gnus-group-mail-2-empty {
  /* gnus-group-mail-2-empty */
  color: #cd6090;
}
.org-gnus-group-mail-3 {
  /* gnus-group-mail-3 */
  color: #8b008b;
  font-weight: bold;
}
.org-gnus-group-mail-3-empty {
  /* gnus-group-mail-3-empty */
  color: #8b008b;
}
.org-gnus-group-mail-low {
  /* gnus-group-mail-low */
  color: #8b0a50;
  font-weight: bold;
}
.org-gnus-group-mail-low-empty {
  /* gnus-group-mail-low-empty */
  color: #8b0a50;
}
.org-gnus-group-news-1 {
  /* gnus-group-news-1 */
  color: #228b22;
  font-weight: bold;
}
.org-gnus-group-news-1-empty {
  /* gnus-group-news-1-empty */
  color: #228b22;
}
.org-gnus-group-news-2 {
  /* gnus-group-news-2 */
  color: #53868b;
  font-weight: bold;
}
.org-gnus-group-news-2-empty {
  /* gnus-group-news-2-empty */
  color: #53868b;
}
.org-gnus-group-news-3 {
  /* gnus-group-news-3 */
  font-weight: bold;
}
.org-gnus-group-news-3-empty {
}
.org-gnus-group-news-4 {
  /* gnus-group-news-4 */
  font-weight: bold;
}
.org-gnus-group-news-4-empty {
}
.org-gnus-group-news-5 {
  /* gnus-group-news-5 */
  font-weight: bold;
}
.org-gnus-group-news-5-empty {
}
.org-gnus-group-news-6 {
  /* gnus-group-news-6 */
  font-weight: bold;
}
.org-gnus-group-news-6-empty {
}
.org-gnus-group-news-low {
  /* gnus-group-news-low */
  color: #006400;
  font-weight: bold;
}
.org-gnus-group-news-low-empty {
  /* gnus-group-news-low-empty */
  color: #006400;
}
.org-gnus-header-content {
  /* gnus-header-content */
  color: #8b3a3a;
  font-style: italic;
}
.org-gnus-header-from {
  /* gnus-header-from */
  color: #cd0000;
}
.org-gnus-header-name {
  /* gnus-header-name */
  color: #b03060;
}
.org-gnus-header-newsgroups {
  /* gnus-header-newsgroups */
  color: #191970;
  font-style: italic;
}
.org-gnus-header-subject {
  /* gnus-header-subject */
  color: #8b0000;
}
.org-gnus-signature {
  /* gnus-signature */
  font-style: italic;
}
.org-gnus-splash {
  /* gnus-splash */
  color: #888888;
}
.org-gnus-summary-cancelled {
  /* gnus-summary-cancelled */
  color: #ffff00;
  background-color: #000000;
}
.org-gnus-summary-high-ancient {
  /* gnus-summary-high-ancient */
  color: #4169e1;
  font-weight: bold;
}
.org-gnus-summary-high-read {
  /* gnus-summary-high-read */
  color: #006400;
  font-weight: bold;
}
.org-gnus-summary-high-ticked {
  /* gnus-summary-high-ticked */
  color: #b22222;
  font-weight: bold;
}
.org-gnus-summary-high-undownloaded {
  /* gnus-summary-high-undownloaded */
  color: #008b8b;
  font-weight: bold;
}
.org-gnus-summary-high-unread {
  /* gnus-summary-high-unread */
  font-weight: bold;
}
.org-gnus-summary-low-ancient {
  /* gnus-summary-low-ancient */
  color: #4169e1;
  font-style: italic;
}
.org-gnus-summary-low-read {
  /* gnus-summary-low-read */
  color: #006400;
  font-style: italic;
}
.org-gnus-summary-low-ticked {
  /* gnus-summary-low-ticked */
  color: #b22222;
  font-style: italic;
}
.org-gnus-summary-low-undownloaded {
  /* gnus-summary-low-undownloaded */
  color: #008b8b;
  font-style: italic;
}
.org-gnus-summary-low-unread {
  /* gnus-summary-low-unread */
  font-style: italic;
}
.org-gnus-summary-normal-ancient {
  /* gnus-summary-normal-ancient */
  color: #4169e1;
}
.org-gnus-summary-normal-read {
  /* gnus-summary-normal-read */
  color: #006400;
}
.org-gnus-summary-normal-ticked {
  /* gnus-summary-normal-ticked */
  color: #b22222;
}
.org-gnus-summary-normal-undownloaded {
  /* gnus-summary-normal-undownloaded */
  color: #008b8b;
}
.org-gnus-summary-normal-unread {
}
.org-gnus-summary-selected {
  /* gnus-summary-selected */
  text-decoration: underline;
}
.org-header-line {
  /* header-line */
  color: #333333;
  background-color: #e5e5e5;
}
.org-header-line-highlight {
  /* header-line-highlight */
  background-color: #b4eeb4;
}
.org-helm-action {
  /* helm-action */
  text-decoration: underline;
}
.org-helm-buffer-archive {
  /* helm-buffer-archive */
  color: #ffd700;
}
.org-helm-buffer-directory {
  /* helm-buffer-directory */
  color: #8b0000;
  background-color: #d3d3d3;
}
.org-helm-buffer-file {
  /* helm-buffer-file */
  color: #483d8b;
}
.org-helm-buffer-modified {
  /* helm-buffer-modified */
  color: #b22222;
}
.org-helm-buffer-not-saved {
  /* helm-buffer-not-saved */
  color: #ee6363;
}
.org-helm-buffer-process {
  /* helm-buffer-process */
  color: #cd6839;
}
.org-helm-buffer-saved-out {
  /* helm-buffer-saved-out */
  color: #ff0000;
  background-color: #000000;
}
.org-helm-buffer-size {
  /* helm-buffer-size */
  color: #708090;
}
.org-helm-candidate-number {
  /* helm-candidate-number */
  color: #000000;
  background-color: #faffb5;
}
.org-helm-candidate-number-suspended {
  /* helm-candidate-number-suspended */
  color: #000000;
  background-color: #faffb5;
}
.org-helm-delete-async-message {
  /* helm-delete-async-message */
  color: #ffff00;
}
.org-helm-eob-line {
  /* helm-eob-line */
  color: #000000;
  background-color: #ffffff;
}
.org-helm-etags-file {
  /* helm-etags-file */
  color: #8b814c;
  text-decoration: underline;
}
.org-helm-ff-backup-file {
  /* helm-ff-backup-file */
  color: #696969;
}
.org-helm-ff-denied {
  /* helm-ff-denied */
  color: #ff0000;
  background-color: #000000;
}
.org-helm-ff-directory {
  /* helm-ff-directory */
  color: #8b0000;
  background-color: #d3d3d3;
}
.org-helm-ff-dirs {
  /* helm-ff-dirs */
  color: #0000ff;
}
.org-helm-ff-dotted-directory {
  /* helm-ff-dotted-directory */
  color: #000000;
  background-color: #696969;
}
.org-helm-ff-dotted-symlink-directory {
  /* helm-ff-dotted-symlink-directory */
  color: #ff8c00;
  background-color: #696969;
}
.org-helm-ff-executable {
  /* helm-ff-executable */
  color: #00ff00;
}
.org-helm-ff-file {
  /* helm-ff-file */
  color: #483d8b;
}
.org-helm-ff-file-extension {
  /* helm-ff-file-extension */
  color: #ff00ff;
}
.org-helm-ff-invalid-symlink {
  /* helm-ff-invalid-symlink */
  color: #000000;
  background-color: #ff0000;
}
.org-helm-ff-pipe {
  /* helm-ff-pipe */
  color: #ffff00;
  background-color: #000000;
}
.org-helm-ff-prefix {
  /* helm-ff-prefix */
  color: #000000;
  background-color: #ffff00;
}
.org-helm-ff-rsync-progress {
  /* helm-ff-rsync-progress */
  color: #ff0000;
  font-weight: bold;
}
.org-helm-ff-socket {
  /* helm-ff-socket */
  color: #ff1493;
}
.org-helm-ff-suid {
  /* helm-ff-suid */
  color: #ffffff;
  background-color: #ff0000;
}
.org-helm-ff-symlink {
  /* helm-ff-symlink */
  color: #b22222;
}
.org-helm-ff-truename {
  /* helm-ff-truename */
  color: #8b2252;
}
.org-helm-grep-cmd-line {
  /* helm-grep-cmd-line */
  color: #228b22;
}
.org-helm-grep-file {
  /* helm-grep-file */
  color: #8a2be2;
  text-decoration: underline;
}
.org-helm-grep-finish {
  /* helm-grep-finish */
  color: #00ff00;
}
.org-helm-grep-lineno {
  /* helm-grep-lineno */
  color: #ff7f00;
}
.org-helm-grep-match {
  /* helm-grep-match */
  color: #b00000;
}
.org-helm-header {
  /* helm-header */
  color: #333333;
  background-color: #e5e5e5;
}
.org-helm-header-line-left-margin {
  /* helm-header-line-left-margin */
  color: #000000;
  background-color: #ffff00;
}
.org-helm-helper {
  /* helm-helper */
  color: #333333;
  background-color: #e5e5e5;
}
.org-helm-history-deleted {
  /* helm-history-deleted */
  color: #000000;
  background-color: #ff0000;
}
.org-helm-history-remote {
  /* helm-history-remote */
  color: #ff6a6a;
}
.org-helm-locate-finish {
  /* helm-locate-finish */
  color: #00ff00;
}
.org-helm-mark-prefix {
  /* helm-mark-prefix */
  color: #000000;
  background-color: #ffffff;
}
.org-helm-match {
  /* helm-match */
  color: #b00000;
}
.org-helm-match-item {
  /* helm-match-item */
  color: #b0e2ff;
  background-color: #cd00cd;
}
.org-helm-minibuffer-prompt {
  /* helm-minibuffer-prompt */
  color: #0000cd;
}
.org-helm-moccur-buffer {
  /* helm-moccur-buffer */
  color: #00ced1;
  text-decoration: underline;
}
.org-helm-no-file-buffer-modified {
  /* helm-no-file-buffer-modified */
  color: #ffa500;
  background-color: #000000;
}
.org-helm-non-file-buffer {
  /* helm-non-file-buffer */
  font-style: italic;
}
.org-helm-prefarg {
  /* helm-prefarg */
  color: #ff0000;
}
.org-helm-resume-need-update {
  /* helm-resume-need-update */
  background-color: #ff0000;
}
.org-helm-selection {
  /* helm-selection */
  background-color: #b5ffd1;
}
.org-helm-selection-line {
  /* helm-selection-line */
  background-color: #b4eeb4;
}
.org-helm-separator {
  /* helm-separator */
  color: #ffbfb5;
}
.org-helm-source-header {
  /* helm-source-header */
  color: #000000;
  background-color: #abd7f0;
  font-size: 130%;
  font-weight: bold;
}
.org-helm-visible-mark {
  /* helm-visible-mark */
  background-color: #d1f5ea;
}
.org-help-argument-name {
  /* help-argument-name */
  font-style: italic;
}
.org-highlight {
  /* highlight */
  background-color: #b4eeb4;
}
.org-hl-line {
  /* hl-line */
  background-color: #b4eeb4;
}
.org-holiday {
  /* holiday */
  background-color: #ffc0cb;
}
.org-homoglyph {
  /* homoglyph */
  color: #a52a2a;
}
.org-hydra-face-amaranth {
  /* hydra-face-amaranth */
  color: #E52B50;
  font-weight: bold;
}
.org-hydra-face-blue {
  /* hydra-face-blue */
  color: #0000FF;
  font-weight: bold;
}
.org-hydra-face-pink {
  /* hydra-face-pink */
  color: #FF6EB4;
  font-weight: bold;
}
.org-hydra-face-red {
  /* hydra-face-red */
  color: #FF0000;
  font-weight: bold;
}
.org-hydra-face-teal {
  /* hydra-face-teal */
  color: #367588;
  font-weight: bold;
}
.org-ido-first-match {
  /* ido-first-match */
  font-weight: bold;
}
.org-ido-incomplete-regexp {
  /* ido-incomplete-regexp */
  color: #ff0000;
  font-weight: bold;
}
.org-ido-indicator {
  /* ido-indicator */
  color: #ffff00;
  background-color: #ff0000;
}
.org-ido-only-match {
  /* ido-only-match */
  color: #228b22;
}
.org-ido-subdir {
  /* ido-subdir */
  color: #ff0000;
}
.org-ido-virtual {
  /* ido-virtual */
  color: #483d8b;
}
.org-info-header-node {
  /* info-header-node */
  color: #a52a2a;
  font-weight: bold;
  font-style: italic;
}
.org-info-header-xref {
  /* info-header-xref */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-info-index-match {
  /* info-index-match */
  background-color: #ffff00;
}
.org-info-menu-header {
  /* info-menu-header */
  font-weight: bold;
}
.org-info-menu-star {
  /* info-menu-star */
  color: #ff0000;
}
.org-info-node {
  /* info-node */
  color: #a52a2a;
  font-weight: bold;
  font-style: italic;
}
.org-info-quoted {
}
.org-info-title-1 {
  /* info-title-1 */
  font-size: 172%;
  font-weight: bold;
}
.org-info-title-2 {
  /* info-title-2 */
  font-size: 144%;
  font-weight: bold;
}
.org-info-title-3 {
  /* info-title-3 */
  font-size: 120%;
  font-weight: bold;
}
.org-info-title-4 {
  /* info-title-4 */
  font-weight: bold;
}
.org-info-xref {
  /* info-xref */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-internal-border {
}
.org-isearch {
  /* isearch */
  color: #b0e2ff;
  background-color: #cd00cd;
}
.org-isearch-fail {
  /* isearch-fail */
  background-color: #ffc1c1;
}
.org-italic {
  /* italic */
  font-style: italic;
}
.org-ivy-action {
  /* ivy-action */
  color: #483d8b;
}
.org-ivy-completions-annotations {
  /* ivy-completions-annotations */
  font-style: italic;
}
.org-ivy-confirm {
  /* ivy-confirm-face */
  color: #228b22;
}
.org-ivy-current-match {
  /* ivy-current-match */
  color: #ffffff;
  background-color: #1a4b77;
}
.org-ivy-cursor {
  /* ivy-cursor */
  color: #ffffff;
  background-color: #000000;
}
.org-ivy-grep-info {
  /* ivy-grep-info */
  color: #228b22;
  font-weight: bold;
}
.org-ivy-grep-line-number {
  /* ivy-grep-line-number */
  color: #a020f0;
}
.org-ivy-highlight {
  /* ivy-highlight-face */
  background-color: #b4eeb4;
}
.org-ivy-match-required {
  /* ivy-match-required-face */
  color: #ff0000;
}
.org-ivy-minibuffer-match-face-1 {
  /* ivy-minibuffer-match-face-1 */
  background-color: #d3d3d3;
}
.org-ivy-minibuffer-match-face-2 {
  /* ivy-minibuffer-match-face-2 */
  background-color: #e99ce8;
  font-weight: bold;
}
.org-ivy-minibuffer-match-face-3 {
  /* ivy-minibuffer-match-face-3 */
  background-color: #bbbbff;
  font-weight: bold;
}
.org-ivy-minibuffer-match-face-4 {
  /* ivy-minibuffer-match-face-4 */
  background-color: #ffbbff;
  font-weight: bold;
}
.org-ivy-minibuffer-match-highlight {
  /* ivy-minibuffer-match-highlight */
  background-color: #b4eeb4;
}
.org-ivy-modified-buffer {
  /* ivy-modified-buffer */
  color: #000000;
  background-color: #ffffff;
}
.org-ivy-modified-outside-buffer {
  /* ivy-modified-outside-buffer */
  color: #000000;
  background-color: #ffffff;
}
.org-ivy-org {
  /* ivy-org */
  color: #b22222;
}
.org-ivy-prompt-match {
  /* ivy-prompt-match */
  color: #ffffff;
  background-color: #1a4b77;
}
.org-ivy-remote {
  /* ivy-remote */
  color: #110099;
}
.org-ivy-separator {
  /* ivy-separator */
  color: #8b2252;
}
.org-ivy-subdir {
  /* ivy-subdir */
  color: #0000ff;
}
.org-ivy-virtual {
  /* ivy-virtual */
  color: #483d8b;
}
.org-ivy-yanked-word {
  /* ivy-yanked-word */
  background-color: #b4eeb4;
}
.org-keyword {
  /* font-lock-keyword-face */
  color: #a020f0;
}
.org-lazy-highlight {
  /* lazy-highlight */
  background-color: #afeeee;
}
.org-line-number-current-line {
  /* line-number-current-line */
  color: #7f7f7f;
  background-color: #ffffff;
}
.org-line-number-major-tick {
  /* line-number-major-tick */
  background-color: #d9d9d9;
  font-weight: bold;
}
.org-line-number-minor-tick {
  /* line-number-minor-tick */
  background-color: #f2f2f2;
  font-weight: bold;
}
.org-link {
  /* link */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-link-visited {
  /* link-visited */
  color: #8b008b;
  text-decoration: underline;
}
.org-log-edit-header {
  /* log-edit-header */
  color: #a020f0;
}
.org-log-edit-summary {
  /* log-edit-summary */
  color: #0000ff;
}
.org-log-edit-unknown-header {
  /* log-edit-unknown-header */
  color: #b22222;
}
.org-lsp-details {
  /* lsp-details-face */
  color: #7f7f7f;
  font-size: 80%;
}
.org-lsp-face-highlight-read {
  /* lsp-face-highlight-read */
  background-color: #b4eeb4;
  text-decoration: underline;
}
.org-lsp-face-highlight-textual {
  /* lsp-face-highlight-textual */
  background-color: #b4eeb4;
}
.org-lsp-face-highlight-write {
  /* lsp-face-highlight-write */
  background-color: #b4eeb4;
  font-weight: bold;
}
.org-lsp-face-rename {
  /* lsp-face-rename */
  text-decoration: underline;
}
.org-lsp-installation-buffer {
  /* lsp-installation-buffer-face */
  color: #00ff00;
}
.org-lsp-installation-finished-buffer {
  /* lsp-installation-finished-buffer-face */
  color: #ffa500;
}
.org-lsp-rename-placeholder {
  /* lsp-rename-placeholder-face */
  color: #a0522d;
}
.org-lsp-signature {
  /* lsp-signature-face */
  color: #7f7f7f;
  font-size: 80%;
}
.org-lsp-signature-posframe {
  /* lsp-signature-posframe */
  color: #000000;
  background-color: #ffffe0;
}
.org-lv-separator {
  /* lv-separator */
  background-color: #cccccc;
}
.org-magit-bisect-bad {
  /* magit-bisect-bad */
  color: #8b3a3a;
}
.org-magit-bisect-good {
  /* magit-bisect-good */
  color: #556b2f;
}
.org-magit-bisect-skip {
  /* magit-bisect-skip */
  color: #b8860b;
}
.org-magit-blame-date {
}
.org-magit-blame-dimmed {
  /* magit-blame-dimmed */
  color: #7f7f7f;
}
.org-magit-blame-hash {
}
.org-magit-blame-heading {
  /* magit-blame-heading */
  color: #000000;
  background-color: #cccccc;
}
.org-magit-blame-highlight {
  /* magit-blame-highlight */
  color: #000000;
  background-color: #cccccc;
}
.org-magit-blame-margin {
  /* magit-blame-margin */
  color: #000000;
  background-color: #cccccc;
}
.org-magit-blame-name {
}
.org-magit-blame-summary {
}
.org-magit-branch-current {
  /* magit-branch-current */
  color: #4a708b;
}
.org-magit-branch-local {
  /* magit-branch-local */
  color: #4a708b;
}
.org-magit-branch-remote {
  /* magit-branch-remote */
  color: #6e8b3d;
}
.org-magit-branch-remote-head {
  /* magit-branch-remote-head */
  color: #6e8b3d;
}
.org-magit-branch-upstream {
  /* magit-branch-upstream */
  font-style: italic;
}
.org-magit-cherry-equivalent {
  /* magit-cherry-equivalent */
  color: #ff00ff;
}
.org-magit-cherry-unmatched {
  /* magit-cherry-unmatched */
  color: #00ffff;
}
.org-magit-diff-added {
  /* magit-diff-added */
  color: #22aa22;
  background-color: #ddffdd;
}
.org-magit-diff-added-highlight {
  /* magit-diff-added-highlight */
  color: #22aa22;
  background-color: #cceecc;
}
.org-magit-diff-base {
  /* magit-diff-base */
  color: #aaaa11;
  background-color: #ffffcc;
}
.org-magit-diff-base-highlight {
  /* magit-diff-base-highlight */
  color: #aaaa11;
  background-color: #eeeebb;
}
.org-magit-diff-conflict-heading {
  /* magit-diff-conflict-heading */
  color: #4d4d4d;
  background-color: #cccccc;
}
.org-magit-diff-context {
  /* magit-diff-context */
  color: #7f7f7f;
}
.org-magit-diff-context-highlight {
  /* magit-diff-context-highlight */
  color: #7f7f7f;
  background-color: #f2f2f2;
}
.org-magit-diff-file-heading {
  /* magit-diff-file-heading */
  font-weight: bold;
}
.org-magit-diff-file-heading-highlight {
  /* magit-diff-file-heading-highlight */
  background-color: #f2f2f2;
}
.org-magit-diff-file-heading-selection {
  /* magit-diff-file-heading-selection */
  color: #8b4c39;
  background-color: #f2f2f2;
}
.org-magit-diff-hunk-heading {
  /* magit-diff-hunk-heading */
  color: #4d4d4d;
  background-color: #cccccc;
}
.org-magit-diff-hunk-heading-highlight {
  /* magit-diff-hunk-heading-highlight */
  color: #4d4d4d;
  background-color: #bfbfbf;
}
.org-magit-diff-hunk-heading-selection {
  /* magit-diff-hunk-heading-selection */
  color: #8b4c39;
  background-color: #bfbfbf;
}
.org-magit-diff-hunk-region {
  /* magit-diff-hunk-region */
  font-weight: bold;
}
.org-magit-diff-lines-boundary {
  /* magit-diff-lines-boundary */
  color: #4d4d4d;
  background-color: #cd8162;
}
.org-magit-diff-lines-heading {
  /* magit-diff-lines-heading */
  color: #4d4d4d;
  background-color: #cd8162;
}
.org-magit-diff-our {
  /* magit-diff-our */
  color: #aa2222;
  background-color: #ffdddd;
}
.org-magit-diff-our-highlight {
  /* magit-diff-our-highlight */
  color: #aa2222;
  background-color: #eecccc;
}
.org-magit-diff-removed {
  /* magit-diff-removed */
  color: #aa2222;
  background-color: #ffdddd;
}
.org-magit-diff-removed-highlight {
  /* magit-diff-removed-highlight */
  color: #aa2222;
  background-color: #eecccc;
}
.org-magit-diff-revision-summary {
  /* magit-diff-revision-summary */
  color: #4d4d4d;
  background-color: #cccccc;
}
.org-magit-diff-revision-summary-highlight {
  /* magit-diff-revision-summary-highlight */
  color: #4d4d4d;
  background-color: #bfbfbf;
}
.org-magit-diff-their {
  /* magit-diff-their */
  color: #22aa22;
  background-color: #ddffdd;
}
.org-magit-diff-their-highlight {
  /* magit-diff-their-highlight */
  color: #22aa22;
  background-color: #cceecc;
}
.org-magit-diff-whitespace-warning {
  /* magit-diff-whitespace-warning */
  background-color: #ff0000;
}
.org-magit-diffstat-added {
  /* magit-diffstat-added */
  color: #22aa22;
}
.org-magit-diffstat-removed {
  /* magit-diffstat-removed */
  color: #aa2222;
}
.org-magit-dimmed {
  /* magit-dimmed */
  color: #7f7f7f;
}
.org-magit-filename {
}
.org-magit-hash {
  /* magit-hash */
  color: #999999;
}
.org-magit-head {
  /* magit-head */
  color: #4a708b;
}
.org-magit-header-line {
  /* magit-header-line */
  color: #8b6508;
  font-weight: bold;
}
.org-magit-header-line-key {
  /* magit-header-line-key */
  color: #483d8b;
}
.org-magit-header-line-log-select {
  /* magit-header-line-log-select */
  font-weight: bold;
}
.org-magit-keyword {
  /* magit-keyword */
  color: #8b2252;
}
.org-magit-keyword-squash {
  /* magit-keyword-squash */
  color: #ff0000;
  font-weight: bold;
}
.org-magit-log-author {
  /* magit-log-author */
  color: #b22222;
}
.org-magit-log-date {
  /* magit-log-date */
  color: #4d4d4d;
}
.org-magit-log-graph {
  /* magit-log-graph */
  color: #4d4d4d;
}
.org-magit-mode-line-process {
  /* magit-mode-line-process */
  font-weight: bold;
}
.org-magit-mode-line-process-error {
  /* magit-mode-line-process-error */
  color: #ff0000;
  font-weight: bold;
}
.org-magit-popup-argument {
  /* magit-popup-argument */
  color: #ff0000;
  font-weight: bold;
}
.org-magit-popup-disabled-argument {
  /* magit-popup-disabled-argument */
  color: #7f7f7f;
}
.org-magit-popup-heading {
  /* magit-popup-heading */
  color: #a020f0;
}
.org-magit-popup-key {
  /* magit-popup-key */
  color: #483d8b;
}
.org-magit-popup-option-value {
  /* magit-popup-option-value */
  color: #8b2252;
}
.org-magit-process-ng {
  /* magit-process-ng */
  color: #ff0000;
  font-weight: bold;
}
.org-magit-process-ok {
  /* magit-process-ok */
  color: #00ff00;
  font-weight: bold;
}
.org-magit-reflog-amend {
  /* magit-reflog-amend */
  color: #ff00ff;
}
.org-magit-reflog-checkout {
  /* magit-reflog-checkout */
  color: #0000ff;
}
.org-magit-reflog-cherry-pick {
  /* magit-reflog-cherry-pick */
  color: #00ff00;
}
.org-magit-reflog-commit {
  /* magit-reflog-commit */
  color: #00ff00;
}
.org-magit-reflog-merge {
  /* magit-reflog-merge */
  color: #00ff00;
}
.org-magit-reflog-other {
  /* magit-reflog-other */
  color: #00ffff;
}
.org-magit-reflog-rebase {
  /* magit-reflog-rebase */
  color: #ff00ff;
}
.org-magit-reflog-remote {
  /* magit-reflog-remote */
  color: #00ffff;
}
.org-magit-reflog-reset {
  /* magit-reflog-reset */
  color: #ff0000;
}
.org-magit-refname {
  /* magit-refname */
  color: #4d4d4d;
}
.org-magit-refname-pullreq {
  /* magit-refname-pullreq */
  color: #4d4d4d;
}
.org-magit-refname-stash {
  /* magit-refname-stash */
  color: #4d4d4d;
}
.org-magit-refname-wip {
  /* magit-refname-wip */
  color: #4d4d4d;
}
.org-magit-section-heading {
  /* magit-section-heading */
  color: #8b6508;
  font-weight: bold;
}
.org-magit-section-heading-selection {
  /* magit-section-heading-selection */
  color: #8b4c39;
}
.org-magit-section-highlight {
  /* magit-section-highlight */
  background-color: #f2f2f2;
}
.org-magit-section-secondary-heading {
  /* magit-section-secondary-heading */
  font-weight: bold;
}
.org-magit-sequence-done {
  /* magit-sequence-done */
  color: #999999;
}
.org-magit-sequence-drop {
  /* magit-sequence-drop */
  color: #cd5c5c;
}
.org-magit-sequence-exec {
  /* magit-sequence-exec */
  color: #999999;
}
.org-magit-sequence-head {
  /* magit-sequence-head */
  color: #4a708b;
}
.org-magit-sequence-onto {
  /* magit-sequence-onto */
  color: #999999;
}
.org-magit-sequence-part {
  /* magit-sequence-part */
  color: #8b6914;
}
.org-magit-sequence-pick {
  /* magit-sequence-pick */
  color: #000000;
  background-color: #ffffff;
}
.org-magit-sequence-stop {
  /* magit-sequence-stop */
  color: #6e8b3d;
}
.org-magit-signature-bad {
  /* magit-signature-bad */
  color: #ff0000;
  font-weight: bold;
}
.org-magit-signature-error {
  /* magit-signature-error */
  color: #add8e6;
}
.org-magit-signature-expired {
  /* magit-signature-expired */
  color: #ffa500;
}
.org-magit-signature-expired-key {
  /* magit-signature-expired-key */
  color: #ffa500;
}
.org-magit-signature-good {
  /* magit-signature-good */
  color: #00ff00;
}
.org-magit-signature-revoked {
  /* magit-signature-revoked */
  color: #d02090;
}
.org-magit-signature-untrusted {
  /* magit-signature-untrusted */
  color: #66cdaa;
}
.org-magit-tag {
  /* magit-tag */
  color: #8b6914;
}
.org-markdown-blockquote {
  /* markdown-blockquote-face */
  color: #8b2252;
}
.org-markdown-bold {
  /* markdown-bold-face */
  font-weight: bold;
}
.org-markdown-code {
}
.org-markdown-comment {
  /* markdown-comment-face */
  color: #b22222;
}
.org-markdown-footnote-marker {
  /* markdown-footnote-marker-face */
  color: #7f7f7f;
}
.org-markdown-footnote-text {
  /* markdown-footnote-text-face */
  color: #b22222;
}
.org-markdown-gfm-checkbox {
  /* markdown-gfm-checkbox-face */
  color: #483d8b;
}
.org-markdown-header-delimiter {
  /* markdown-header-delimiter-face */
  color: #7f7f7f;
}
.org-markdown-header-face-1 {
  /* markdown-header-face-1 */
  color: #0000ff;
  font-weight: bold;
}
.org-markdown-header-face-2 {
  /* markdown-header-face-2 */
  color: #0000ff;
  font-weight: bold;
}
.org-markdown-header-face-3 {
  /* markdown-header-face-3 */
  color: #0000ff;
  font-weight: bold;
}
.org-markdown-header-face-4 {
  /* markdown-header-face-4 */
  color: #0000ff;
  font-weight: bold;
}
.org-markdown-header-face-5 {
  /* markdown-header-face-5 */
  color: #0000ff;
  font-weight: bold;
}
.org-markdown-header-face-6 {
  /* markdown-header-face-6 */
  color: #0000ff;
  font-weight: bold;
}
.org-markdown-header-rule {
  /* markdown-header-rule-face */
  color: #7f7f7f;
}
.org-markdown-highlight {
  /* markdown-highlight-face */
  background-color: #b4eeb4;
}
.org-markdown-hr {
  /* markdown-hr-face */
  color: #7f7f7f;
}
.org-markdown-html-attr-name {
  /* markdown-html-attr-name-face */
  color: #a0522d;
}
.org-markdown-html-attr-value {
  /* markdown-html-attr-value-face */
  color: #8b2252;
}
.org-markdown-html-entity {
  /* markdown-html-entity-face */
  color: #a0522d;
}
.org-markdown-html-tag-delimiter {
  /* markdown-html-tag-delimiter-face */
  color: #7f7f7f;
}
.org-markdown-html-tag-name {
  /* markdown-html-tag-name-face */
  color: #228b22;
}
.org-markdown-italic {
  /* markdown-italic-face */
  font-style: italic;
}
.org-markdown-language-info {
  /* markdown-language-info-face */
  color: #8b2252;
}
.org-markdown-language-keyword {
  /* markdown-language-keyword-face */
  color: #228b22;
}
.org-markdown-line-break {
  /* markdown-line-break-face */
  color: #008b8b;
  text-decoration: underline;
}
.org-markdown-link {
  /* markdown-link-face */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-markdown-link-title {
  /* markdown-link-title-face */
  color: #b22222;
}
.org-markdown-list {
  /* markdown-list-face */
  color: #7f7f7f;
}
.org-markdown-markup {
  /* markdown-markup-face */
  color: #7f7f7f;
}
.org-markdown-math {
  /* markdown-math-face */
  color: #8b2252;
}
.org-markdown-metadata-key {
  /* markdown-metadata-key-face */
  color: #a0522d;
}
.org-markdown-metadata-value {
  /* markdown-metadata-value-face */
  color: #8b2252;
}
.org-markdown-missing-link {
  /* markdown-missing-link-face */
  color: #ff0000;
  font-weight: bold;
}
.org-markdown-plain-url {
  /* markdown-plain-url-face */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-markdown-reference {
  /* markdown-reference-face */
  color: #7f7f7f;
}
.org-markdown-strike-through {
  /* markdown-strike-through-face */
  text-decoration: line-through;
}
.org-markdown-url {
  /* markdown-url-face */
  color: #8b2252;
}
.org-match {
  /* match */
  background-color: #ffff00;
}
.org-menu {
}
.org-message-cited-text-1 {
  /* message-cited-text-1 */
  color: #ff0000;
}
.org-message-cited-text-2 {
  /* message-cited-text-2 */
  color: #8b0000;
}
.org-message-cited-text-3 {
  /* message-cited-text-3 */
  color: #698b22;
}
.org-message-cited-text-4 {
  /* message-cited-text-4 */
  color: #36648b;
}
.org-message-header-cc {
  /* message-header-cc */
  color: #191970;
}
.org-message-header-name {
  /* message-header-name */
  color: #6495ed;
}
.org-message-header-newsgroups {
  /* message-header-newsgroups */
  color: #00008b;
  font-weight: bold;
  font-style: italic;
}
.org-message-header-other {
  /* message-header-other */
  color: #4682b4;
}
.org-message-header-subject {
  /* message-header-subject */
  color: #000080;
  font-weight: bold;
}
.org-message-header-to {
  /* message-header-to */
  color: #191970;
  font-weight: bold;
}
.org-message-header-xheader {
  /* message-header-xheader */
  color: #0000ff;
}
.org-message-mml {
  /* message-mml */
  color: #228b22;
}
.org-message-separator {
  /* message-separator */
  color: #a52a2a;
}
.org-minibuffer-prompt {
  /* minibuffer-prompt */
  color: #0000cd;
}
.org-mm-command-output {
  /* mm-command-output */
  color: #cd0000;
}
.org-mm-uu-extract {
  /* mm-uu-extract */
  color: #006400;
  background-color: #ffffe0;
}
.org-mode-line {
  /* mode-line */
  color: #000000;
  background-color: #bfbfbf;
}
.org-mode-line-buffer-id {
  /* mode-line-buffer-id */
  font-weight: bold;
}
.org-mode-line-emphasis {
  /* mode-line-emphasis */
  font-weight: bold;
}
.org-mode-line-highlight {
}
.org-mode-line-inactive {
  /* mode-line-inactive */
  color: #333333;
  background-color: #e5e5e5;
}
.org-mouse {
}
.org-mouse-drag-and-drop-region {
  /* mouse-drag-and-drop-region */
  background-color: gtk_selection_bg_color;
}
.org-mu4e-attach-number {
  /* mu4e-attach-number-face */
  color: #a0522d;
  font-weight: bold;
}
.org-mu4e-cited-1 {
  /* mu4e-cited-1-face */
  color: #483d8b;
  font-style: italic;
}
.org-mu4e-cited-2 {
  /* mu4e-cited-2-face */
  color: #483d8b;
  font-style: italic;
}
.org-mu4e-cited-3 {
  /* mu4e-cited-3-face */
  color: #a0522d;
  font-style: italic;
}
.org-mu4e-cited-4 {
  /* mu4e-cited-4-face */
  color: #a020f0;
  font-style: italic;
}
.org-mu4e-cited-5 {
  /* mu4e-cited-5-face */
  color: #b22222;
  font-style: italic;
}
.org-mu4e-cited-6 {
  /* mu4e-cited-6-face */
  color: #b22222;
  font-style: italic;
}
.org-mu4e-cited-7 {
  /* mu4e-cited-7-face */
  color: #228b22;
  font-style: italic;
}
.org-mu4e-compose-header {
  /* mu4e-compose-header-face */
  color: #a52a2a;
  font-style: italic;
}
.org-mu4e-compose-separator {
  /* mu4e-compose-separator-face */
  color: #a52a2a;
  font-style: italic;
}
.org-mu4e-contact {
  /* mu4e-contact-face */
  color: #a0522d;
}
.org-mu4e-context {
  /* mu4e-context-face */
  color: #228b22;
  font-weight: bold;
}
.org-mu4e-draft {
  /* mu4e-draft-face */
  color: #8b2252;
}
.org-mu4e-flagged {
  /* mu4e-flagged-face */
  color: #008b8b;
  font-weight: bold;
}
.org-mu4e-footer {
  /* mu4e-footer-face */
  color: #b22222;
}
.org-mu4e-forwarded {
  /* mu4e-forwarded-face */
  color: #483d8b;
}
.org-mu4e-header {
  /* mu4e-header-face */
  color: #000000;
  background-color: #ffffff;
}
.org-mu4e-header-highlight {
  /* mu4e-header-highlight-face */
  background-color: #b4eeb4;
  font-weight: bold;
  text-decoration: underline;
}
.org-mu4e-header-key {
  /* mu4e-header-key-face */
  color: #6495ed;
  font-weight: bold;
}
.org-mu4e-header-marks {
  /* mu4e-header-marks-face */
  color: #483d8b;
}
.org-mu4e-header-title {
  /* mu4e-header-title-face */
  color: #228b22;
}
.org-mu4e-header-value {
  /* mu4e-header-value-face */
  color: #228b22;
}
.org-mu4e-highlight {
  /* mu4e-highlight-face */
  background-color: #b4eeb4;
}
.org-mu4e-link {
  /* mu4e-link-face */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-mu4e-modeline {
  /* mu4e-modeline-face */
  color: #8b2252;
  font-weight: bold;
}
.org-mu4e-ok {
  /* mu4e-ok-face */
  color: #b22222;
  font-weight: bold;
}
.org-mu4e-region-code {
  /* mu4e-region-code */
  background-color: #2f4f4f;
}
.org-mu4e-replied {
  /* mu4e-replied-face */
  color: #483d8b;
}
.org-mu4e-special-header-value {
  /* mu4e-special-header-value-face */
  color: #483d8b;
}
.org-mu4e-system {
  /* mu4e-system-face */
  color: #b22222;
  font-style: italic;
}
.org-mu4e-title {
  /* mu4e-title-face */
  color: #228b22;
  font-weight: bold;
}
.org-mu4e-trashed {
  /* mu4e-trashed-face */
  color: #b22222;
  text-decoration: line-through;
}
.org-mu4e-unread {
  /* mu4e-unread-face */
  color: #a020f0;
  font-weight: bold;
}
.org-mu4e-url-number {
  /* mu4e-url-number-face */
  color: #008b8b;
  font-weight: bold;
}
.org-mu4e-view-body {
  /* mu4e-view-body-face */
  color: #000000;
  background-color: #ffffff;
}
.org-mu4e-warning {
  /* mu4e-warning-face */
  color: #ff0000;
  font-weight: bold;
}
.org-negation-char {
}
.org-next-error {
  /* next-error */
  background-color: gtk_selection_bg_color;
}
.org-nobreak-hyphen {
  /* nobreak-hyphen */
  color: #a52a2a;
}
.org-nobreak-space {
  /* nobreak-space */
  color: #a52a2a;
  text-decoration: underline;
}
.org-org-agenda-calendar-event {
  /* org-agenda-calendar-event */
  color: #000000;
  background-color: #ffffff;
}
.org-org-agenda-calendar-sexp {
  /* org-agenda-calendar-sexp */
  color: #000000;
  background-color: #ffffff;
}
.org-org-agenda-clocking {
  /* org-agenda-clocking */
  background-color: #ffff00;
}
.org-org-agenda-column-dateline {
  /* org-agenda-column-dateline */
  background-color: #e5e5e5;
}
.org-org-agenda-current-time {
  /* org-agenda-current-time */
  color: #b8860b;
}
.org-org-agenda-date {
  /* org-agenda-date */
  color: #0000ff;
}
.org-org-agenda-date-today {
  /* org-agenda-date-today */
  color: #0000ff;
  font-weight: bold;
  font-style: italic;
}
.org-org-agenda-date-weekend {
  /* org-agenda-date-weekend */
  color: #0000ff;
  font-weight: bold;
}
.org-org-agenda-date-weekend-today {
  /* org-agenda-date-weekend-today */
  color: #0000ff;
  font-weight: bold;
  font-style: italic;
}
.org-org-agenda-diary {
  /* org-agenda-diary */
  color: #000000;
  background-color: #ffffff;
}
.org-org-agenda-dimmed-todo {
  /* org-agenda-dimmed-todo-face */
  color: #7f7f7f;
}
.org-org-agenda-done {
  /* org-agenda-done */
  color: #228b22;
}
.org-org-agenda-filter-category {
  /* org-agenda-filter-category */
  color: #000000;
  background-color: #bfbfbf;
}
.org-org-agenda-filter-effort {
  /* org-agenda-filter-effort */
  color: #000000;
  background-color: #bfbfbf;
}
.org-org-agenda-filter-regexp {
  /* org-agenda-filter-regexp */
  color: #000000;
  background-color: #bfbfbf;
}
.org-org-agenda-filter-tags {
  /* org-agenda-filter-tags */
  color: #000000;
  background-color: #bfbfbf;
}
.org-org-agenda-restriction-lock {
  /* org-agenda-restriction-lock */
  background-color: #eeeeee;
}
.org-org-agenda-structure {
  /* org-agenda-structure */
  color: #0000ff;
}
.org-org-agenda-structure-secondary {
  /* org-agenda-structure-secondary */
  color: #0000ff;
}
.org-org-archived {
  /* org-archived */
  color: #7f7f7f;
}
.org-org-block-begin-line {
  /* org-block-begin-line */
  color: #b22222;
}
.org-org-block-end-line {
  /* org-block-end-line */
  color: #b22222;
}
.org-org-checkbox {
  /* org-checkbox */
  font-weight: bold;
}
.org-org-checkbox-statistics-done {
  /* org-checkbox-statistics-done */
  color: #228b22;
  font-weight: bold;
}
.org-org-checkbox-statistics-todo {
  /* org-checkbox-statistics-todo */
  color: #ff0000;
  font-weight: bold;
}
.org-org-cite {
  /* org-cite */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-org-cite-key {
  /* org-cite-key */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-org-clock-overlay {
  /* org-clock-overlay */
  color: #000000;
  background-color: #d3d3d3;
}
.org-org-column {
  /* org-column */
  background-color: #e5e5e5;
}
.org-org-column-title {
  /* org-column-title */
  background-color: #e5e5e5;
  font-weight: bold;
  text-decoration: underline;
}
.org-org-date {
  /* org-date */
  color: #a020f0;
  text-decoration: underline;
}
.org-org-date-selected {
  /* org-date-selected */
  color: #ff0000;
}
.org-org-default {
  /* org-default */
  color: #000000;
  background-color: #ffffff;
}
.org-org-dispatcher-highlight {
  /* org-dispatcher-highlight */
  color: #00008b;
  background-color: #c6e2ff;
  font-weight: bold;
}
.org-org-document-info {
  /* org-document-info */
  color: #191970;
}
.org-org-document-info-keyword {
  /* org-document-info-keyword */
  color: #7f7f7f;
}
.org-org-document-title {
  /* org-document-title */
  color: #191970;
  font-weight: bold;
}
.org-org-done {
  /* org-done */
  color: #228b22;
  font-weight: bold;
}
.org-org-drawer {
  /* org-drawer */
  color: #0000ff;
}
.org-org-ellipsis {
  /* org-ellipsis */
  color: #b8860b;
  text-decoration: underline;
}
.org-org-footnote {
  /* org-footnote */
  color: #a020f0;
  text-decoration: underline;
}
.org-org-formula {
  /* org-formula */
  color: #b22222;
}
.org-org-habit-alert {
  /* org-habit-alert-face */
  background-color: #f5f946;
}
.org-org-habit-alert-future {
  /* org-habit-alert-future-face */
  background-color: #fafca9;
}
.org-org-habit-clear {
  /* org-habit-clear-face */
  background-color: #8270f9;
}
.org-org-habit-clear-future {
  /* org-habit-clear-future-face */
  background-color: #d6e4fc;
}
.org-org-habit-overdue {
  /* org-habit-overdue-face */
  background-color: #f9372d;
}
.org-org-habit-overdue-future {
  /* org-habit-overdue-future-face */
  background-color: #fc9590;
}
.org-org-habit-ready {
  /* org-habit-ready-face */
  background-color: #4df946;
}
.org-org-habit-ready-future {
  /* org-habit-ready-future-face */
  background-color: #acfca9;
}
.org-org-headline-done {
  /* org-headline-done */
  color: #bc8f8f;
}
.org-org-headline-todo {
  /* org-headline-todo */
  color: #8b0000;
}
.org-org-hide {
  /* org-hide */
  color: #ffffff;
}
.org-org-imminent-deadline {
  /* org-imminent-deadline */
  color: #ff0000;
  font-weight: bold;
}
.org-org-latex-and-related {
  /* org-latex-and-related */
  color: #8b4513;
}
.org-org-level-1 {
  /* org-level-1 */
  color: #0000ff;
}
.org-org-level-2 {
  /* org-level-2 */
  color: #a0522d;
}
.org-org-level-3 {
  /* org-level-3 */
  color: #a020f0;
}
.org-org-level-4 {
  /* org-level-4 */
  color: #b22222;
}
.org-org-level-5 {
  /* org-level-5 */
  color: #228b22;
}
.org-org-level-6 {
  /* org-level-6 */
  color: #008b8b;
}
.org-org-level-7 {
  /* org-level-7 */
  color: #483d8b;
}
.org-org-level-8 {
  /* org-level-8 */
  color: #8b2252;
}
.org-org-link {
  /* org-link */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-org-list-dt {
  /* org-list-dt */
  font-weight: bold;
}
.org-org-macro {
  /* org-macro */
  color: #8b4513;
}
.org-org-mode-line-clock {
  /* org-mode-line-clock */
  color: #000000;
  background-color: #bfbfbf;
}
.org-org-mode-line-clock-overrun {
  /* org-mode-line-clock-overrun */
  color: #000000;
  background-color: #ff0000;
}
.org-org-priority {
  /* org-priority */
  color: #a020f0;
}
.org-org-property-value {
}
.org-org-quote {
  /* org-quote */
  color: #7f7f7f;
}
.org-org-ref-acronym {
  /* org-ref-acronym-face */
  color: #ee7600;
  text-decoration: underline;
}
.org-org-ref-cite {
  /* org-ref-cite-face */
  color: #228b22;
  text-decoration: underline;
}
.org-org-ref-glossary {
  /* org-ref-glossary-face */
  color: #8968cd;
  text-decoration: underline;
}
.org-org-ref-label {
  /* org-ref-label-face */
  color: #8b008b;
  text-decoration: underline;
}
.org-org-ref-ref {
  /* org-ref-ref-face */
  color: #8b0000;
  text-decoration: underline;
}
.org-org-scheduled {
  /* org-scheduled */
  color: #006400;
}
.org-org-scheduled-previously {
  /* org-scheduled-previously */
  color: #b22222;
}
.org-org-scheduled-today {
  /* org-scheduled-today */
  color: #006400;
}
.org-org-sexp-date {
  /* org-sexp-date */
  color: #a020f0;
}
.org-org-special-keyword {
  /* org-special-keyword */
  color: #a020f0;
}
.org-org-table {
  /* org-table */
  color: #0000ff;
}
.org-org-table-header {
  /* org-table-header */
  color: #000000;
  background-color: #d3d3d3;
}
.org-org-tag {
  /* org-tag */
  font-weight: bold;
}
.org-org-tag-group {
  /* org-tag-group */
  font-weight: bold;
}
.org-org-target {
  /* org-target */
  text-decoration: underline;
}
.org-org-time-grid {
  /* org-time-grid */
  color: #b8860b;
}
.org-org-todo {
  /* org-todo */
  color: #ff0000;
  font-weight: bold;
}
.org-org-upcoming-deadline {
  /* org-upcoming-deadline */
  color: #b22222;
}
.org-org-upcoming-distant-deadline {
  /* org-upcoming-distant-deadline */
  color: #000000;
  background-color: #ffffff;
}
.org-org-verse {
  /* org-verse */
  color: #7f7f7f;
}
.org-org-warning {
  /* org-warning */
  color: #ff0000;
  font-weight: bold;
}
.org-outline-1 {
  /* outline-1 */
  color: #0000ff;
}
.org-outline-2 {
  /* outline-2 */
  color: #a0522d;
}
.org-outline-3 {
  /* outline-3 */
  color: #a020f0;
}
.org-outline-4 {
  /* outline-4 */
  color: #b22222;
}
.org-outline-5 {
  /* outline-5 */
  color: #228b22;
}
.org-outline-6 {
  /* outline-6 */
  color: #008b8b;
}
.org-outline-7 {
  /* outline-7 */
  color: #483d8b;
}
.org-outline-8 {
  /* outline-8 */
  color: #8b2252;
}
.org-package-description {
  /* package-description */
  color: #000000;
  background-color: #ffffff;
}
.org-package-name {
  /* package-name */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-package-status-avail-obso {
  /* package-status-avail-obso */
  color: #ff0000;
  font-weight: bold;
}
.org-package-status-available {
  /* package-status-available */
  color: #000000;
  background-color: #ffffff;
}
.org-package-status-built-in {
  /* package-status-built-in */
  color: #483d8b;
}
.org-package-status-dependency {
  /* package-status-dependency */
  color: #b22222;
}
.org-package-status-disabled {
  /* package-status-disabled */
  color: #ff0000;
  font-weight: bold;
}
.org-package-status-external {
  /* package-status-external */
  color: #483d8b;
}
.org-package-status-held {
  /* package-status-held */
  color: #008b8b;
}
.org-package-status-incompat {
  /* package-status-incompat */
  color: #ff0000;
  font-weight: bold;
}
.org-package-status-installed {
  /* package-status-installed */
  color: #b22222;
}
.org-package-status-unsigned {
  /* package-status-unsigned */
  color: #ff0000;
  font-weight: bold;
}
.org-preprocessor {
  /* font-lock-preprocessor-face */
  color: #483d8b;
}
.org-query-replace {
  /* query-replace */
  color: #b0e2ff;
  background-color: #cd00cd;
}
.org-read-multiple-choice {
  /* read-multiple-choice-face */
  font-weight: bold;
  text-decoration: underline;
}
.org-regexp-grouping-backslash {
  /* font-lock-regexp-grouping-backslash */
  font-weight: bold;
}
.org-regexp-grouping-construct {
  /* font-lock-regexp-grouping-construct */
  font-weight: bold;
}
.org-region {
  /* region */
  background-color: gtk_selection_bg_color;
}
.org-rmail-header-name {
  /* rmail-header-name */
  color: #0000ff;
}
.org-rmail-highlight {
  /* rmail-highlight */
  background-color: #b4eeb4;
}
.org-scroll-bar {
}
.org-secondary-selection {
  /* secondary-selection */
  background-color: #ffff00;
}
.org-sgml-namespace {
  /* sgml-namespace */
  color: #483d8b;
}
.org-shadow {
  /* shadow */
  color: #7f7f7f;
}
.org-show-paren-match {
  /* show-paren-match */
  background-color: #40e0d0;
}
.org-show-paren-match-expression {
  /* show-paren-match-expression */
  background-color: #40e0d0;
}
.org-show-paren-mismatch {
  /* show-paren-mismatch */
  color: #ffffff;
  background-color: #a020f0;
}
.org-shr-abbreviation {
  /* shr-abbreviation */
  text-decoration: underline;
}
.org-shr-link {
  /* shr-link */
  color: #3a5fcd;
  text-decoration: underline;
}
.org-shr-selected-link {
  /* shr-selected-link */
  color: #3a5fcd;
  background-color: #ff0000;
  text-decoration: underline;
}
.org-shr-strike-through {
  /* shr-strike-through */
  text-decoration: line-through;
}
.org-smerge-base {
  /* smerge-base */
  background-color: #ffffaa;
}
.org-smerge-lower {
  /* smerge-lower */
  background-color: #ddffdd;
}
.org-smerge-markers {
  /* smerge-markers */
  background-color: #d9d9d9;
}
.org-smerge-refined-added {
  /* smerge-refined-added */
  background-color: #aaffaa;
}
.org-smerge-refined-changed {
}
.org-smerge-refined-removed {
  /* smerge-refined-removed */
  background-color: #ffbbbb;
}
.org-smerge-upper {
  /* smerge-upper */
  background-color: #ffdddd;
}
.org-string {
  /* font-lock-string-face */
  color: #8b2252;
}
.org-success {
  /* success */
  color: #228b22;
  font-weight: bold;
}
.org-tab-bar {
  /* tab-bar */
  color: #000000;
  background-color: #d9d9d9;
}
.org-tab-bar-tab {
  /* tab-bar-tab */
  color: #000000;
  background-color: #d9d9d9;
}
.org-tab-bar-tab-inactive {
  /* tab-bar-tab-inactive */
  color: #000000;
  background-color: #bfbfbf;
}
.org-tab-line {
  /* tab-line */
  color: #000000;
  background-color: #d9d9d9;
  font-size: 90%;
}
.org-table-cell {
  /* table-cell */
  color: #e5e5e5;
  background-color: #0000ff;
}
.org-tabulated-list-fake-header {
  /* tabulated-list-fake-header */
  font-weight: bold;
  text-decoration: underline;
  text-decoration: overline;
}
.org-tool-bar {
  /* tool-bar */
  color: #000000;
  background-color: #bfbfbf;
}
.org-tooltip {
  /* tooltip */
  color: #000000;
  background-color: #ffffe0;
}
.org-trailing-whitespace {
  /* trailing-whitespace */
  background-color: #ff0000;
}
.org-transient-active-infix {
  /* transient-active-infix */
  background-color: #ffff00;
}
.org-transient-amaranth {
  /* transient-amaranth */
  color: #E52B50;
}
.org-transient-argument {
  /* transient-argument */
  color: #ff0000;
  font-weight: bold;
}
.org-transient-blue {
  /* transient-blue */
  color: #0000ff;
}
.org-transient-disabled-suffix {
  /* transient-disabled-suffix */
  color: #000000;
  background-color: #ff0000;
  font-weight: bold;
}
.org-transient-enabled-suffix {
  /* transient-enabled-suffix */
  color: #000000;
  background-color: #00ff00;
  font-weight: bold;
}
.org-transient-heading {
  /* transient-heading */
  color: #a020f0;
}
.org-transient-higher-level {
  /* transient-higher-level */
  text-decoration: underline;
}
.org-transient-inactive-argument {
  /* transient-inactive-argument */
  color: #7f7f7f;
}
.org-transient-inactive-value {
  /* transient-inactive-value */
  color: #7f7f7f;
}
.org-transient-inapt-suffix {
  /* transient-inapt-suffix */
  color: #7f7f7f;
  font-style: italic;
}
.org-transient-key {
  /* transient-key */
  color: #483d8b;
}
.org-transient-mismatched-key {
  /* transient-mismatched-key */
  text-decoration: underline;
}
.org-transient-nonstandard-key {
  /* transient-nonstandard-key */
  text-decoration: underline;
}
.org-transient-pink {
  /* transient-pink */
  color: #FF6EB4;
}
.org-transient-red {
  /* transient-red */
  color: #ff0000;
}
.org-transient-separator {
  /* transient-separator */
  background-color: #cccccc;
}
.org-transient-teal {
  /* transient-teal */
  color: #367588;
}
.org-transient-unreachable {
  /* transient-unreachable */
  color: #7f7f7f;
}
.org-transient-unreachable-key {
  /* transient-unreachable-key */
  color: #7f7f7f;
}
.org-transient-value {
  /* transient-value */
  color: #8b2252;
}
.org-tty-menu-disabled {
  /* tty-menu-disabled-face */
  color: #d3d3d3;
  background-color: #0000ff;
}
.org-tty-menu-enabled {
  /* tty-menu-enabled-face */
  color: #ffff00;
  background-color: #0000ff;
  font-weight: bold;
}
.org-tty-menu-selected {
  /* tty-menu-selected-face */
  background-color: #ff0000;
}
.org-type {
  /* font-lock-type-face */
  color: #228b22;
}
.org-underline {
  /* underline */
  text-decoration: underline;
}
.org-variable-name {
  /* font-lock-variable-name-face */
  color: #a0522d;
}
.org-variable-pitch {
}
.org-vc-conflict-state {
}
.org-vc-edited-state {
}
.org-vc-locally-added-state {
}
.org-vc-locked-state {
}
.org-vc-missing-state {
}
.org-vc-needs-update-state {
}
.org-vc-removed-state {
}
.org-vc-state-base {
}
.org-vc-up-to-date-state {
}
.org-vertical-border {
}
.org-warning {
  /* warning */
  color: #ff8c00;
  font-weight: bold;
}
.org-warning-1 {
  /* font-lock-warning-face */
  color: #ff0000;
  font-weight: bold;
}
.org-which-func {
  /* which-func */
  color: #0000ff;
}
.org-widget-button {
  /* widget-button */
  font-weight: bold;
}
.org-widget-button-pressed {
  /* widget-button-pressed */
  color: #ff0000;
}
.org-widget-documentation {
  /* widget-documentation */
  color: #006400;
}
.org-widget-field {
  /* widget-field */
  background-color: #d9d9d9;
}
.org-widget-inactive {
  /* widget-inactive */
  color: #7f7f7f;
}
.org-widget-single-line-field {
  /* widget-single-line-field */
  background-color: #d9d9d9;
}
.org-window-divider {
  /* window-divider */
  color: #999999;
}
.org-window-divider-first-pixel {
  /* window-divider-first-pixel */
  color: #cccccc;
}
.org-window-divider-last-pixel {
  /* window-divider-last-pixel */
  color: #666666;
}
.org-xref-file-header {
  /* xref-file-header */
  color: #228b22;
  font-weight: bold;
}
.org-xref-line-number {
  /* xref-line-number */
  color: #a020f0;
}
.org-xref-match {
  /* xref-match */
  background-color: #b4eeb4;
}
.org-yas--field-debug {
}
.org-yas-field-highlight {
  /* yas-field-highlight-face */
  background-color: gtk_selection_bg_color;
}

a {
  color: inherit;
  background-color: inherit;
  font: inherit;
  text-decoration: underline;
}
